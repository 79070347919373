import { DateTime } from 'luxon'

interface IFormatDateTimeOptions {
  hideYearIfCurrent: boolean
}

const defaultOptions: IFormatDateTimeOptions = {
  hideYearIfCurrent: true,
}

const formatDateTime = (
  date: DateTime | string | Date | null,
  options?: Partial<IFormatDateTimeOptions>
): string => {
  const { hideYearIfCurrent }: IFormatDateTimeOptions = { ...defaultOptions, ...options }

  if (!date) {
    return '—'
  }

  const now = DateTime.now()

  const parsed = (() => {
    if (typeof date === 'string') {
      return DateTime.fromISO(date)
    }

    if (DateTime.isDateTime(date)) {
      return date
    }

    return DateTime.fromJSDate(date)
  })()

  const dateFormat = `dd. MMM ${
    hideYearIfCurrent && parsed.diff(now, ['months']).months < 12 ? '' : 'yyyy '
  }HH:mm`

  return parsed.setLocale('en-US').toFormat(dateFormat).replace(/24:/, '00:').toUpperCase()
}

export const formatToLocalDateTime = (
  date: DateTime | string | null,
  timezone?: string | null,
  format = 'MMM dd, HH:mm'
): string => {
  if (!date || typeof date === null || (date instanceof DateTime && !date.isValid)) {
    return '—'
  }

  const zone = timezone ?? 'local'

  if (DateTime.isDateTime(date)) {
    return date.setZone(zone).toFormat(format)
  }

  return DateTime.fromISO(date, { zone }).toFormat(format)
}

export default formatDateTime
