import React, { ReactNode } from 'react'
import type { TableVariant } from './Table'

interface TableHeadProps {
  variant?: TableVariant
  children: ReactNode
}

function TableHead({ children, variant }: TableHeadProps): JSX.Element {
  return (
    <thead
      className={[
        'text-xs font-normal text-left text-gray-600 border-b border-gray-200',
        variant === 'standalone' && 'bg-gray-50',
      ]
        .filter(Boolean)
        .join(' ')}
    >
      {children}
    </thead>
  )
}

TableHead.defaultProps = {
  variant: 'standalone',
}

export default TableHead
