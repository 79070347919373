import getDateTimeForFlightLeg from '@tilla/backend/shared/utils/get-date-time-for-flight-leg'
import clsx from 'clsx'
import Link from 'next/link'
import { MouseEvent, useCallback, useContext, useMemo, useState } from 'react'
import formatDateTime from 'utils/formatDateTime'
import getCrewChangeRouteForUserRole from 'utils/getCrewChangeRouteForUserRole'
import { NotificationsQuery } from '~api/notifications-gql.generated'
import { FlightSegmentChangeType, NotificationType } from '~api/types.generated'
import ChevronDownIcon from '~components/ui/Icon/ChevronDownIcon'
import FlightCancelledIcon from '~components/ui/Icon/FlightCancelledIcon'
import Icon from '~components/ui/Icon/Icon'
import { NotificationsContext } from '~context/NotificationsContext'
import useCurrentUser from '~hooks/useCurrentUser'
import useRelativeDateTime from '~hooks/useRelativeDateTime'
import useToggle from '~hooks/useToggle'
import { useTrackEvent } from '~hooks/useTrackEvent'
import DismissNotificationButton from './DismissNotificationButton'

interface FlightSegmentChangedCardProps {
  changedSegmentNotification: NotificationsQuery['notifications'][number]['changedSegmentsNotifications'][number]
  notification: NotificationsQuery['notifications'][number]
}

export default function FlightSegmentChangedCard(props: FlightSegmentChangedCardProps) {
  const { changedSegmentNotification, notification } = props
  const { dismissNotifications, markNotificationsAsRead } = useContext(NotificationsContext)
  const { user } = useCurrentUser()
  const [isHovered, setIsHovered] = useState(false)
  const [expanded, toggleExpanded] = useToggle(false)
  const [isDismissing, setIsDismissing] = useState(false)
  const cancelledSegmentChanges = changedSegmentNotification.segmentChanges.filter(
    (change) => change.changeType === FlightSegmentChangeType.Cancelled
  )
  const showViewMore = cancelledSegmentChanges.length > 1
  const relativeCreatedAt = useRelativeDateTime(changedSegmentNotification.notification.createdAt)
  const trackEvent = useTrackEvent()

  const roleRoute = useMemo<string>(() => {
    return getCrewChangeRouteForUserRole(user.role, notification.bucketId)
  }, [user.role, notification.bucketId])

  const dismissHandler = async (e: MouseEvent, restore?: boolean) => {
    e.preventDefault()
    setIsDismissing(true)
    await dismissNotifications(
      [changedSegmentNotification.notification],
      NotificationType.ChangedSegments,
      restore
    )
    setIsDismissing(false)
  }

  const onClickHandler = useCallback(() => {
    if (!changedSegmentNotification.notification.read) {
      markNotificationsAsRead([changedSegmentNotification.notification.id])
    }
    trackEvent({
      event: 'Clicked on a notification',
      metadata: {
        mixpanelProperties: {
          type: NotificationType.ChangedSegments,
          age: relativeCreatedAt,
          vessel: notification.vessel.charterName,
          ETA: notification.vesselSchedule.ETA,
          ccUrl: `https://${window.location.host}/crew-changes/${notification.bucketId}`,
        },
      },
    })
  }, [
    changedSegmentNotification.notification.id,
    changedSegmentNotification.notification.read,
    markNotificationsAsRead,
    notification.bucketId,
    notification.vessel.charterName,
    notification.vesselSchedule.ETA,
    relativeCreatedAt,
    trackEvent,
  ])

  const getMatchingFlightSegment = useCallback(
    (flightSegmentId: string) => {
      return changedSegmentNotification.flightBooking.flight.segments.find(
        (segment) => segment.id === flightSegmentId
      )
    },
    [changedSegmentNotification.flightBooking.flight.segments]
  )

  return (
    <div
      className="mx-2 my-2 cursor-pointer hover:bg-indigo-50 active:bg-indigo-100 hover:rounded-lg"
      onMouseLeave={() => setIsHovered(false)}
      onMouseEnter={() => setIsHovered(true)}
    >
      <Link href={roleRoute} passHref onClick={onClickHandler}>
        <div className="flex flex-row my-2 mx-2 gap-3">
          <div className="text-brand my-2 items-start">
            <Icon icon={FlightCancelledIcon} size="regular" className=" text-indigo-500" />
          </div>

          <div className="flex flex-row justify-between w-full">
            <div className="flex flex-col justify-start">
              <p className="text-sm font-semibold">{`Flight Segment${
                cancelledSegmentChanges.length > 1 ? 's' : ''
              } Cancelled`}</p>

              <p className="text-sm font-light">{`${changedSegmentNotification.seaman.firstName} ${changedSegmentNotification.seaman.lastName}`}</p>

              <div className="space-y-2 mt-2">
                {cancelledSegmentChanges.map((segmentChange, index) => {
                  const matchingFlightSegment = getMatchingFlightSegment(
                    segmentChange.flightSegmentId
                  )
                  if (!matchingFlightSegment || (index >= 1 && !expanded)) {
                    return null
                  }

                  const date =
                    segmentChange.departure?.date || matchingFlightSegment.departure?.date || ''
                  const time =
                    segmentChange.departure?.time || matchingFlightSegment.departure?.time || ''
                  const { timezoneOlson } = notification.vesselSchedule.seaport
                  const departureIata =
                    segmentChange.departure?.iata || matchingFlightSegment.departure?.iata || ''
                  const arrivalIata =
                    segmentChange.arrival?.iata || matchingFlightSegment.arrival?.iata || ''

                  return (
                    <p key={segmentChange.id} className="text-sm font-light">{`${formatDateTime(
                      getDateTimeForFlightLeg({
                        date,
                        time,
                        timezoneOlson,
                      })
                    )} ${departureIata} -> ${formatDateTime(
                      getDateTimeForFlightLeg({
                        date,
                        time,
                        timezoneOlson,
                      })
                    )} ${arrivalIata}`}</p>
                  )
                })}
              </div>

              {showViewMore && (
                <div className="flex flex-row mt-2">
                  <div
                    className="flex flex-row text-indigo-500 hover:text-indigo-700 cursor-pointer"
                    onClick={(e) => {
                      toggleExpanded()
                      e.preventDefault()
                    }}
                  >
                    <p className="text-xs ">{`View ${expanded ? 'Less' : 'More'}`}</p>
                    <ChevronDownIcon
                      className={clsx('h-4 w-4', { 'transform-gpu rotate-180': expanded })}
                    />
                  </div>
                </div>
              )}
              <p className="text-sm mt-2">{relativeCreatedAt}</p>
            </div>
            {isHovered && (
              <DismissNotificationButton
                dismissHandler={dismissHandler}
                isDismissing={isDismissing}
              />
            )}
          </div>
        </div>
      </Link>
    </div>
  )
}
