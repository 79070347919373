/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import { AirlineFieldsFragmentDoc } from './airlines-gql.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type DetectFlightItineraryChangesQueryVariables = Types.Exact<{
  params: Types.DetectFlightItineraryChangesParams
}>

export type DetectFlightItineraryChangesQuery = {
  detectFlightItineraryChanges: {
    segmentsChanges: Array<{
      id: string
      changeType: Types.FlightSegmentChangeType
      flightSegmentId: string
      flightNumber: number | null
      departure: {
        date: string | null
        time: string | null
        iata: string | null
        timezoneOlson: string | null
        countryAlpha2: string | null
        name: string | null
        terminal: string | null
        city: string | null
      } | null
      arrival: {
        date: string | null
        time: string | null
        iata: string | null
        timezoneOlson: string | null
        countryAlpha2: string | null
        name: string | null
        terminal: string | null
        city: string | null
      } | null
      marketingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
      operatingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
    } | null>
  }
}

export type SegmentChangeDetailsFieldsFragment = {
  id: string
  changeType: Types.FlightSegmentChangeType
  flightSegmentId: string
  flightNumber: number | null
  departure: {
    date: string | null
    time: string | null
    iata: string | null
    timezoneOlson: string | null
    countryAlpha2: string | null
    name: string | null
    terminal: string | null
    city: string | null
  } | null
  arrival: {
    date: string | null
    time: string | null
    iata: string | null
    timezoneOlson: string | null
    countryAlpha2: string | null
    name: string | null
    terminal: string | null
    city: string | null
  } | null
  marketingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
  operatingCarrier: {
    id: string
    createdAt: string
    updatedAt: string
    iata: string
    name: string
    logo: string | null
  } | null
}

export const SegmentChangeDetailsFieldsFragmentDoc = gql`
  fragment SegmentChangeDetailsFields on SegmentChangeDetails {
    id
    changeType
    flightSegmentId
    departure {
      date
      time
      iata
      timezoneOlson
      countryAlpha2
      name
      terminal
      city
    }
    arrival {
      date
      time
      iata
      timezoneOlson
      countryAlpha2
      name
      terminal
      city
    }
    marketingCarrier {
      ...AirlineFields
    }
    operatingCarrier {
      ...AirlineFields
    }
    flightNumber
  }
  ${AirlineFieldsFragmentDoc}
`
export const DetectFlightItineraryChangesDocument = gql`
  query DetectFlightItineraryChanges($params: DetectFlightItineraryChangesParams!) {
    detectFlightItineraryChanges(params: $params) {
      segmentsChanges {
        ...SegmentChangeDetailsFields
      }
    }
  }
  ${SegmentChangeDetailsFieldsFragmentDoc}
`

/**
 * __useDetectFlightItineraryChangesQuery__
 *
 * To run a query within a React component, call `useDetectFlightItineraryChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetectFlightItineraryChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetectFlightItineraryChangesQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useDetectFlightItineraryChangesQuery(
  baseOptions: Apollo.QueryHookOptions<
    DetectFlightItineraryChangesQuery,
    DetectFlightItineraryChangesQueryVariables
  > &
    ({ variables: DetectFlightItineraryChangesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<
    DetectFlightItineraryChangesQuery,
    DetectFlightItineraryChangesQueryVariables
  >(DetectFlightItineraryChangesDocument, options)
}
export function useDetectFlightItineraryChangesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DetectFlightItineraryChangesQuery,
    DetectFlightItineraryChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<
    DetectFlightItineraryChangesQuery,
    DetectFlightItineraryChangesQueryVariables
  >(DetectFlightItineraryChangesDocument, options)
}
export function useDetectFlightItineraryChangesSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    DetectFlightItineraryChangesQuery,
    DetectFlightItineraryChangesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<
    DetectFlightItineraryChangesQuery,
    DetectFlightItineraryChangesQueryVariables
  >(DetectFlightItineraryChangesDocument, options)
}
export type DetectFlightItineraryChangesQueryHookResult = ReturnType<
  typeof useDetectFlightItineraryChangesQuery
>
export type DetectFlightItineraryChangesLazyQueryHookResult = ReturnType<
  typeof useDetectFlightItineraryChangesLazyQuery
>
export type DetectFlightItineraryChangesSuspenseQueryHookResult = ReturnType<
  typeof useDetectFlightItineraryChangesSuspenseQuery
>
export type DetectFlightItineraryChangesQueryResult = Apollo.QueryResult<
  DetectFlightItineraryChangesQuery,
  DetectFlightItineraryChangesQueryVariables
>
