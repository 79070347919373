/* eslint-disable */
import * as Types from './types.generated'

import { gql } from '@apollo/client'
import {
  CompleteFlightBookingFragmentDoc,
  CompleteFlightBookingRefundFragmentDoc,
  CompleteSeamanFragmentDoc,
  VesselFieldsFragmentDoc,
  ParsedFlightOfferFieldsFragmentDoc,
} from './fragments.generated'
import { SegmentChangeDetailsFieldsFragmentDoc } from './flight-changes-gql.generated'
import * as Apollo from '@apollo/client'
const defaultOptions = {} as const
export type NotificationsQueryVariables = Types.Exact<{ [key: string]: never }>

export type NotificationsQuery = {
  notifications: Array<{
    bucketId: string
    changedSegmentsNotifications: Array<{
      __typename: 'ChangedSegmentsNotification'
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
      flightBooking: {
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        bookCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            lastDetectedStatus: Types.FlightSegmentStatus | null
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: { amount: number; currency: Types.Currency } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }
      seaman: { firstName: string; lastName: string }
      segmentChanges: Array<{
        id: string
        changeType: Types.FlightSegmentChangeType
        flightSegmentId: string
        flightNumber: number | null
        departure: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        arrival: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
    }>
    cheaperOfferNotifications: Array<{
      __typename: 'CheaperOfferNotification'
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
      seaman: { firstName: string; lastName: string }
      cheaperOffer: {
        flightBooking: { id: string; offer: { amount: number; currency: string } }
        flightOffer: { amount: number; currency: string }
      }
    }>
    vesselSchedule: {
      portName: string | null
      ETA: string
      seaport: { timezoneOlson: string | null }
    }
    vessel: { charterName: string }
  }>
}

export type UpdateNotificationsStateMutationVariables = Types.Exact<{
  notificationsToUpdate: Array<Types.NotificationToUpdate> | Types.NotificationToUpdate
  notificationsUpdateType?: Types.InputMaybe<Types.NotificationUpdateType>
}>

export type UpdateNotificationsStateMutation = {
  updateNotificationsState: Array<{
    bucketId: string
    changedSegmentsNotifications: Array<{
      __typename: 'ChangedSegmentsNotification'
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
      flightBooking: {
        id: string
        active: boolean
        monitored: boolean
        ticketLine: string | null
        comment: string | null
        pnr: string | null
        status: Types.FlightBookingStatus
        amadeusSessionId: string | null
        managedByTravelTeam: boolean
        isAmadeus: boolean
        lastTicketingTime: string | null
        lastTicketingTimeSource: Types.LastTicketingTimeSource
        pendingUpdatedByTravelAgentNotification: boolean
        bookCheaperOfferAutomatically: boolean
        ticketedAt: string | null
        bucketId: string | null
        autoTicket: boolean
        travelOfficeConfigurationId: string | null
        flight: {
          id: string
          updatedAt: string
          createdAt: string
          flightHash: string
          majorityCarrier: string | null
          travelOfficeConfigurationId: string
          validatingCarrier: string | null
          segments: Array<{
            id: string
            flightNumber: number
            index: number
            lastDetectedStatus: Types.FlightSegmentStatus | null
            departure: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            arrival: {
              timezoneOlson: string | null
              time: string
              name: string | null
              iata: string
              countryAlpha2: string | null
              city: string | null
              date: string
              terminal: string | null
            }
            marketingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
            operatingCarrier: {
              id: string
              createdAt: string
              updatedAt: string
              iata: string
              name: string
              logo: string | null
            } | null
          }>
          travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
        }
        offer: {
          id: string
          amount: number
          tax: number | null
          passengerType: Types.PassengerType | null
          currency: string
          createdAt: string
          updatedAt: string
          flightId: string
          amadeusSessionId: string | null
          segmentFares: Array<{
            availableSeats: number | null
            fareTypes: Array<Types.FareType>
            fareBasisCode: string
            flightSegmentId: string
            bookingClass: string | null
            baggageDetails: {
              freeBagAllowance: number | null
              freeBagAllowanceType: Types.FreeBagAllowanceType | null
            }
          }>
          rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
          minirules: {
            beforeDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            beforeDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDeparture: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
            afterDepartureNoShow: {
              changeAllowed: boolean | null
              changeMaxPenaltyAmount: number | null
              refundAllowed: boolean | null
              refundMaxPenaltyAmount: number | null
              revalidationAllowed: boolean | null
              revalidationMaxPenaltyAmount: number | null
              isoTicketValidityDate: string | null
              taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
            } | null
          } | null
          taxDetails: Array<{
            amount: number
            code: string | null
            isNonRefundable: boolean | null
          }> | null
        }
        finalOffer: { amount: number; currency: Types.Currency } | null
        seaman: {
          id: string
          firstName: string
          lastName: string
          nationalityIso: string
          rank: number | null
          birthday: string | null
          placeOfBirth: string | null
          firstVaccinationDate: string | null
          firstVaccinationBrand: string | null
          secondVaccinationDate: string | null
          secondVaccinationBrand: string | null
          updatedAt: string
        }
        ticketLineData: { documentNumber: string } | null
        splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
      }
      seaman: { firstName: string; lastName: string }
      segmentChanges: Array<{
        id: string
        changeType: Types.FlightSegmentChangeType
        flightSegmentId: string
        flightNumber: number | null
        departure: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        arrival: {
          date: string | null
          time: string | null
          iata: string | null
          timezoneOlson: string | null
          countryAlpha2: string | null
          name: string | null
          terminal: string | null
          city: string | null
        } | null
        marketingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
        operatingCarrier: {
          id: string
          createdAt: string
          updatedAt: string
          iata: string
          name: string
          logo: string | null
        } | null
      }>
    }>
    cheaperOfferNotifications: Array<{
      __typename: 'CheaperOfferNotification'
      notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
      seaman: { firstName: string; lastName: string }
      cheaperOffer: {
        flightBooking: { id: string; offer: { amount: number; currency: string } }
        flightOffer: { amount: number; currency: string }
      }
    }>
    vesselSchedule: {
      portName: string | null
      ETA: string
      seaport: { timezoneOlson: string | null }
    }
    vessel: { charterName: string }
  }>
}

export type NotificationFieldsFragment = {
  bucketId: string
  changedSegmentsNotifications: Array<{
    __typename: 'ChangedSegmentsNotification'
    notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
    flightBooking: {
      id: string
      active: boolean
      monitored: boolean
      ticketLine: string | null
      comment: string | null
      pnr: string | null
      status: Types.FlightBookingStatus
      amadeusSessionId: string | null
      managedByTravelTeam: boolean
      isAmadeus: boolean
      lastTicketingTime: string | null
      lastTicketingTimeSource: Types.LastTicketingTimeSource
      pendingUpdatedByTravelAgentNotification: boolean
      bookCheaperOfferAutomatically: boolean
      ticketedAt: string | null
      bucketId: string | null
      autoTicket: boolean
      travelOfficeConfigurationId: string | null
      flight: {
        id: string
        updatedAt: string
        createdAt: string
        flightHash: string
        majorityCarrier: string | null
        travelOfficeConfigurationId: string
        validatingCarrier: string | null
        segments: Array<{
          id: string
          flightNumber: number
          index: number
          lastDetectedStatus: Types.FlightSegmentStatus | null
          departure: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          arrival: {
            timezoneOlson: string | null
            time: string
            name: string | null
            iata: string
            countryAlpha2: string | null
            city: string | null
            date: string
            terminal: string | null
          }
          marketingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
          operatingCarrier: {
            id: string
            createdAt: string
            updatedAt: string
            iata: string
            name: string
            logo: string | null
          } | null
        }>
        travelOfficeConfiguration: { agencyName: string; phonePrimary: string }
      }
      offer: {
        id: string
        amount: number
        tax: number | null
        passengerType: Types.PassengerType | null
        currency: string
        createdAt: string
        updatedAt: string
        flightId: string
        amadeusSessionId: string | null
        segmentFares: Array<{
          availableSeats: number | null
          fareTypes: Array<Types.FareType>
          fareBasisCode: string
          flightSegmentId: string
          bookingClass: string | null
          baggageDetails: {
            freeBagAllowance: number | null
            freeBagAllowanceType: Types.FreeBagAllowanceType | null
          }
        }>
        rules: Array<{ rules: Array<string>; id: string; ruleType: Types.FlightOfferRuleType }>
        minirules: {
          beforeDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          beforeDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDeparture: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
          afterDepartureNoShow: {
            changeAllowed: boolean | null
            changeMaxPenaltyAmount: number | null
            refundAllowed: boolean | null
            refundMaxPenaltyAmount: number | null
            revalidationAllowed: boolean | null
            revalidationMaxPenaltyAmount: number | null
            isoTicketValidityDate: string | null
            taxInformation: { areYqYrTaxesNonRefundable: boolean | null } | null
          } | null
        } | null
        taxDetails: Array<{
          amount: number
          code: string | null
          isNonRefundable: boolean | null
        }> | null
      }
      finalOffer: { amount: number; currency: Types.Currency } | null
      seaman: {
        id: string
        firstName: string
        lastName: string
        nationalityIso: string
        rank: number | null
        birthday: string | null
        placeOfBirth: string | null
        firstVaccinationDate: string | null
        firstVaccinationBrand: string | null
        secondVaccinationDate: string | null
        secondVaccinationBrand: string | null
        updatedAt: string
      }
      ticketLineData: { documentNumber: string } | null
      splitTicketingInfo: { splitTicketingBookingId: string; index: number } | null
    }
    seaman: { firstName: string; lastName: string }
    segmentChanges: Array<{
      id: string
      changeType: Types.FlightSegmentChangeType
      flightSegmentId: string
      flightNumber: number | null
      departure: {
        date: string | null
        time: string | null
        iata: string | null
        timezoneOlson: string | null
        countryAlpha2: string | null
        name: string | null
        terminal: string | null
        city: string | null
      } | null
      arrival: {
        date: string | null
        time: string | null
        iata: string | null
        timezoneOlson: string | null
        countryAlpha2: string | null
        name: string | null
        terminal: string | null
        city: string | null
      } | null
      marketingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
      operatingCarrier: {
        id: string
        createdAt: string
        updatedAt: string
        iata: string
        name: string
        logo: string | null
      } | null
    }>
  }>
  cheaperOfferNotifications: Array<{
    __typename: 'CheaperOfferNotification'
    notification: { createdAt: string; dismissed: boolean; id: string; read: boolean }
    seaman: { firstName: string; lastName: string }
    cheaperOffer: {
      flightBooking: { id: string; offer: { amount: number; currency: string } }
      flightOffer: { amount: number; currency: string }
    }
  }>
  vesselSchedule: {
    portName: string | null
    ETA: string
    seaport: { timezoneOlson: string | null }
  }
  vessel: { charterName: string }
}

export const NotificationFieldsFragmentDoc = gql`
  fragment NotificationFields on VesselNotifications {
    changedSegmentsNotifications {
      __typename
      notification {
        createdAt
        dismissed
        id
        read
      }
      flightBooking {
        ...CompleteFlightBooking
      }
      seaman {
        firstName
        lastName
      }
      segmentChanges {
        ...SegmentChangeDetailsFields
      }
    }
    cheaperOfferNotifications {
      notification {
        createdAt
        dismissed
        id
        read
      }
    }
    cheaperOfferNotifications {
      __typename
      seaman {
        firstName
        lastName
      }
      cheaperOffer {
        flightBooking {
          id
          offer {
            amount
            currency
          }
        }
        flightOffer {
          amount
          currency
        }
      }
      notification {
        createdAt
        dismissed
        id
        read
      }
    }
    vesselSchedule {
      portName
      ETA
      seaport {
        timezoneOlson
      }
    }
    vessel {
      charterName
    }
    bucketId
  }
  ${CompleteFlightBookingFragmentDoc}
  ${SegmentChangeDetailsFieldsFragmentDoc}
`
export const NotificationsDocument = gql`
  query Notifications {
    notifications {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationsQuery(
  baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export function useNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export function useNotificationsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useSuspenseQuery<NotificationsQuery, NotificationsQueryVariables>(
    NotificationsDocument,
    options
  )
}
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>
export type NotificationsSuspenseQueryHookResult = ReturnType<typeof useNotificationsSuspenseQuery>
export type NotificationsQueryResult = Apollo.QueryResult<
  NotificationsQuery,
  NotificationsQueryVariables
>
export const UpdateNotificationsStateDocument = gql`
  mutation UpdateNotificationsState(
    $notificationsToUpdate: [NotificationToUpdate!]!
    $notificationsUpdateType: NotificationUpdateType
  ) {
    updateNotificationsState(
      notificationsToUpdate: $notificationsToUpdate
      notificationsUpdateType: $notificationsUpdateType
    ) {
      ...NotificationFields
    }
  }
  ${NotificationFieldsFragmentDoc}
`
export type UpdateNotificationsStateMutationFn = Apollo.MutationFunction<
  UpdateNotificationsStateMutation,
  UpdateNotificationsStateMutationVariables
>

/**
 * __useUpdateNotificationsStateMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsStateMutation, { data, loading, error }] = useUpdateNotificationsStateMutation({
 *   variables: {
 *      notificationsToUpdate: // value for 'notificationsToUpdate'
 *      notificationsUpdateType: // value for 'notificationsUpdateType'
 *   },
 * });
 */
export function useUpdateNotificationsStateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNotificationsStateMutation,
    UpdateNotificationsStateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions }
  return Apollo.useMutation<
    UpdateNotificationsStateMutation,
    UpdateNotificationsStateMutationVariables
  >(UpdateNotificationsStateDocument, options)
}
export type UpdateNotificationsStateMutationHookResult = ReturnType<
  typeof useUpdateNotificationsStateMutation
>
export type UpdateNotificationsStateMutationResult =
  Apollo.MutationResult<UpdateNotificationsStateMutation>
export type UpdateNotificationsStateMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationsStateMutation,
  UpdateNotificationsStateMutationVariables
>
