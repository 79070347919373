import { useContext, useMemo } from 'react'
import { FlightSegmentChangeType } from '~api/types.generated'
import { NotificationPanelTabs, NotificationsContext } from '~context/NotificationsContext'
import EmptyNotifications from './EmptyNotifications/EmptyNotifications'
import CheaperOfferCard from './NotificationCards/CheaperOfferCard'
import FlightSegmentChangedCard from './NotificationCards/FlightSegmentChangedCard'
import VesselHeader from './VesselHeader'

/**
 * TODO:
 * Currently changedSegmentNotification is only including change of type cancelled.
 * We need to include all changes in the future.
 */
export default function NotificationsList() {
  const {
    filteredNotifications: notifications,
    filteredDismissedNotifications: dismissedNotifications,
    currentTab,
  } = useContext(NotificationsContext)

  const notificationsList = useMemo(() => {
    switch (currentTab) {
      case NotificationPanelTabs.Dismissed:
        return dismissedNotifications
      case NotificationPanelTabs.Cancelled:
        return notifications.filter((notification) =>
          notification.changedSegmentsNotifications.some((changedSegment) =>
            changedSegment.segmentChanges.some(
              (change) => change.changeType === FlightSegmentChangeType.Cancelled
            )
          )
        )
      case NotificationPanelTabs.CheaperOffers:
        return notifications.filter(
          (notification) => notification.cheaperOfferNotifications.length > 0
        )
      case NotificationPanelTabs.Active:
      default:
        return notifications
    }
  }, [currentTab, notifications, dismissedNotifications])

  return (
    <div className="flex w-full h-full flex-col">
      {notificationsList.length === 0 && <EmptyNotifications />}

      {notificationsList.map((notification) => (
        <div key={notification.bucketId} className="flex w-full flex-col">
          <VesselHeader notification={notification} />

          {currentTab !== NotificationPanelTabs.CheaperOffers &&
            notification.changedSegmentsNotifications
              .filter((changedSegmentNotification) =>
                changedSegmentNotification.segmentChanges.some(
                  (change) => change.changeType === FlightSegmentChangeType.Cancelled
                )
              )
              .map((changedSegmentNotification) => (
                <FlightSegmentChangedCard
                  changedSegmentNotification={changedSegmentNotification}
                  key={changedSegmentNotification.notification.id}
                  notification={notification}
                />
              ))}

          {currentTab !== NotificationPanelTabs.Cancelled &&
            notification.cheaperOfferNotifications.length > 0 && (
              <CheaperOfferCard notification={notification} key={notification.bucketId} />
            )}
        </div>
      ))}
    </div>
  )
}
