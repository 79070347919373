import { Dialog } from '@headlessui/react'
import clsx from 'clsx'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import Draggable from 'react-draggable'
import ModalContent from './ModalContent'
import ModalHeader from './ModalHeader'

export interface ModalProps {
  open: boolean
  onClose: () => void
  children?: ReactNode
  title?: string | JSX.Element
  inset?: boolean
  width?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl' | 'full'
  verticalAlign?: 'start' | 'center' | 'end'
  horizontalAlign?: 'start' | 'center' | 'end'
  showCloseButton?: boolean
  disableClosing?: boolean
  modalStyling?: string
  modalContentStyling?: string
  disableClosingWhenDismissed?: boolean // Dimissed = clicked outside of modal or pressed esc key
  draggable?: boolean
}

const Modal = ({
  open,
  onClose,
  children,
  title,
  inset,
  modalStyling,
  modalContentStyling,
  width = 'lg',
  verticalAlign = 'center',
  horizontalAlign = 'center',
  showCloseButton = true,
  disableClosing = false,
  disableClosingWhenDismissed = false,
  draggable = true,
}: ModalProps) => {
  const [isBrowser, setIsBrowser] = useState(false)
  const [isDragging, setIsDragging] = useState(false)

  const handleClose = useCallback(() => {
    if (!disableClosing) {
      onClose()
    }
  }, [disableClosing, onClose])

  useEffect(() => {
    setIsBrowser(true)
  }, [])

  if (!isBrowser) {
    return null
  }

  if (!open) {
    return null
  }

  const styling = clsx(
    modalStyling,
    'flex min-h-screen p-6',
    { 'justify-start': horizontalAlign === 'start' },
    { 'justify-center': horizontalAlign === 'center' },
    { 'justify-end': horizontalAlign === 'end' },
    { 'items-start': verticalAlign === 'start' },
    { 'items-center': verticalAlign === 'center' },
    { 'items-end': verticalAlign === 'end' }
  )

  return ReactDOM.createPortal(
    <Dialog
      open={open}
      as="div"
      onClose={disableClosingWhenDismissed ? () => null : handleClose}
      className="fixed inset-0 z-10 overflow-y-auto"
    >
      {/* Backdrop */}
      <div className="fixed inset-0 bg-gray-700 opacity-40" aria-hidden="true" />
      <Draggable handle=".handle" cancel=".cancel-drag" disabled={!draggable}>
        {/* Full-screen scrollable container */}
        <div className={styling}>
          {/* Container to center the panel */}
          <div className="flex min-h-full items-center justify-center p-4 w-full">
            <ModalContent width={width} className={modalContentStyling}>
              <ModalHeader
                handleClose={handleClose}
                disableClosing={disableClosing}
                title={title}
                className={clsx(
                  'handle',
                  draggable && (isDragging ? 'cursor-grabbing' : 'hover:cursor-grab')
                )}
                showCloseButton={showCloseButton}
                onMouseDown={() => setIsDragging(true)}
                onMouseUp={() => setIsDragging(false)}
              />

              <div className={clsx({ 'px-6 pb-6': inset })}>{children}</div>
            </ModalContent>
          </div>
        </div>
      </Draggable>
    </Dialog>,
    document.getElementById('modal-root') || document.body
  )
}

export default Modal
